import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';

const WebAppsPage = (props) => {
  return (
    <Layout>
      <SEO title="Boston Web Development" />
      {/* <PageHeader
        heading="Websites Made to Super Charge Your Business"
        subHeading="ALTdev focuses on building websites that are clean, user friendly, and mobile responsive. Using our unique approach we focus on your business needs and objectives to ensure that your new site leads to more conversions."
        buttonText="Request a Quote"
        buttonLink="/contact/"
        maxWidth={960}
        margin="auto"
      />

      <BannerCTA
        heading="Ready to Super Charge your website?"
        buttonText="Contact Us"
        buttonLink="/contact/"
        altText="Ready to increase the level of Customer Activity on your website?"
      /> */}
    </Layout>
  );
};

export default WebAppsPage;
